import React from "react"
import { graphql } from 'gatsby'
import Prose from "../components/utility/prose"

import Seo from "../components/seo"
import Content from "../components/content"
import moment from "moment-timezone"
import ShareLinks from "../components/utility/ShareLinks" 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'
import PostLocales from "../components/utility/post-locales"

const Post = ({data, location, pageContext}) => {
  const post = data.strapiBlogPost
  const content = post.content
  const baseurl = `https://www.fbmud123.org`

  return (
  <>
    <Seo title={post.title} description={post.description} meta={[
      {
        name: `og:image`,
        content: `${baseurl}${post.thumbnail.localFile.childImageSharp.fixed.src}`,
      },
    ]} />
    <Prose>
      <h1 className="mb-2">{post.title}</h1>

      <PostLocales locale={post.locale} localizations={post.localizations} slug={pageContext.slug} date={pageContext.date} />

      <small className="block mb-4">{pageContext.date_unset}</small>
    </Prose>
    <div class="group inline-block relative pb-2 mb-14">
      <button class="block text-sm font-semibold uppercase cursor-pointer text-slate-900 text-slate-700 bg-slate-200 group-hover:bg-slate-300 dark:bg-slate-600 dark:group-hover:bg-slate-800 dark:text-slate-300 dark:text-white rounded-full mx-4 mx-0 px-4 py-2 border-slate-300 dark:border-slate-700 border-b-2" aria-haspopup="true" aria-controls="dropdown-menu4">
      <span className="mr-2">Share</span>
        <FontAwesomeIcon icon={faAngleDown} />
      </button>
      <div class="absolute hidden group-hover:block left-0 mt-2 z-20 block w-48 pt-2 pb-1 bg-slate-300 dark:bg-slate-900 dark:text-slate-300 rounded-xl shadow-xl" id="dropdown-menu4" role="menu">
          <ShareLinks url={`${baseurl}${location.pathname}`} />
      </div>
      </div>
    <Content content={content}></Content>
  </>
)}

export default Post


export const postQuery = graphql`
  query postById($id: Int!) {
    strapiBlogPost(strapi_id: { eq: $id }) {
      id
      title
      description
      thumbnail {
        localFile {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      publishedAt
      publish_at
      locale
      localizations {
        data {
          id
          attributes {
            locale
          }
        }
      }
      content {
        ... on STRAPI__COMPONENT_PAGE_ALERT {
          ...pageAlert
        }
        ... on STRAPI__COMPONENT_PAGE_CARDS {
          ...pageCards
        }
        ... on STRAPI__COMPONENT_PAGE_GALLERY {
          ...pageGallery
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE {
          ...pageImage
        }
        ... on STRAPI__COMPONENT_PAGE_IMAGE_TEXT {
          ...pageImageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT {
          ...pageText
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_IMAGE {
          ...pageTextImage
        }
        ... on STRAPI__COMPONENT_PAGE_TEXT_TEXT {
          ...pageTextText
        }
        ... on STRAPI__COMPONENT_PAGE_WIDGET {
          ...pageWidget
        }
      }
    }
  }
`
